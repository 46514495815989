import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "password" }
const _hoisted_2 = { class: "shared-page" }
const _hoisted_3 = { class: "centeredContentBox" }
const _hoisted_4 = {
  class: "header-left",
  role: "heading"
}
const _hoisted_5 = { id: "forgotPasswordText" }
const _hoisted_6 = { class: "section" }
const _hoisted_7 = { class: "label-bold" }
const _hoisted_8 = { for: "email" }
const _hoisted_9 = {
  key: 0,
  class: "error"
}
const _hoisted_10 = {
  key: 1,
  class: "error"
}
const _hoisted_11 = { class: "button-section-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('azure_template_heading')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('forgot_password_info')), 1),
        _createElementVNode("form", {
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('email_address')), 1)
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                maxlength: "100",
                type: "text",
                id: "email",
                onKeypress: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args)), ["prevent"]), ["enter"]))
              }, null, 544), [
                [_vModelText, _ctx.email]
              ])
            ]),
            (_ctx.invalidError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.invalid_email), 1))
              : _createCommentVNode("", true),
            (_ctx.requiredError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.required_email), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "button-right",
              type: "button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submitHandler && _ctx.submitHandler(...args))),
              "data-testid": "resetPasswordButton"
            }, _toDisplayString(_ctx.$t('reset_password')), 1),
            _createElementVNode("button", {
              class: "button-right go-back",
              type: "button",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args))),
              "data-testid": "goBackButton"
            }, _toDisplayString(_ctx.$t('go_back')), 1)
          ])
        ], 32)
      ])
    ])
  ]))
}